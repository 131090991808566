* {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

body,
html {
  overflow: hidden;
}

.light .tool-pane {
  -webkit-filter: none;
  filter: none;
}

.dark .tool-pane {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.monaco-editor {
  padding-top: 15px;
}

/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

div[data-evergreen-toaster-container] {
  position: relative;
  z-index: 100;
}

#nprogress .bar {
  background: #fff;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow:
    0 0 10px #22b6ff,
    0 0 5px #a5ddcd;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

.react-monaco-editor-container {
  display: flex;
  flex: 1;
}

#HW_badge_cont {
  position: absolute !important;
  right: 0;
  top: 0;
}

.cf-wrapper {
  padding: 4px !important;
  font-size: 12px !important;
  border-radius: 0 !important;
  line-height: 1.3 !important;
  border-bottom: 1px solid #dfdfdf;
}

.cf-img {
  max-width: 90px !important;
  height: 70px;
}

.cf-img-wrapper {
  margin-right: 8px !important;
}

.cf-powered-by {
  text-align: right !important;
}
.hover_panel {
  background-color: #f5f5f5;
}
.logo-transform {
  font-family:
    Pacaembu,
    Inter,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}
.colorful {
  background: -webkit-linear-gradient(315deg, #eec14b 25%, #647eff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.content {
  margin-inline: auto;
  max-width: 1320px;
  width: 100%;
}
.home-title {
  text-shadow: 2px 2px 50px rgb(0, 0, 0);
  font-size: 18px;
  font-weight: bold;
}

@keyframes BoxShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes BoxHide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.preview-card-container {
  background-color: #fff;
  background-image: linear-gradient(
      45deg,
      #e0e0e0 25%,
      transparent 25%,
      transparent 75%,
      #e0e0e0 75%
    ),
    linear-gradient(
      45deg,
      #e0e0e0 25%,
      transparent 25%,
      transparent 75%,
      #e0e0e0 75%
    );
  background-size: 20px 20px;
  background-position:
    0 0,
    10px 10px;
}

.preview-show {
  animation: BoxShow 0.3s ease-in forwards;
}
.preview-hide {
  animation: BoxHide 0.3s ease-out forwards;
}

.preview-bar-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.beer-handle {
  backdrop-filter: blur(8px);
  background-color: #fff6;
  border-radius: 1000px;
  box-shadow: 0 8px 16px #00000040;
  height: 60px;
  overflow: hidden;
  position: relative;
  width: 60px;
  will-change: var(--framer-will-change-override, transform);
}
.beer-handle::before,
.beer-handle::after {
  content: "";
  height: 24px;
  position: absolute;
  top: calc(50% - 24px / 2);
  width: 24px;
  image-rendering: pixelated;
  flex-shrink: 0;
  background-size: 100% 100%;
}
.beer-handle::before {
  left: 5px;
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M%200%200%20L%2024%200%20L%2024%2024%20L%200%2024%20Z%22%20fill%3D%22transparent%22%3E%3C%2Fpath%3E%3Cpath%20d%3D%22M%2015%206%20L%209%2012%20L%2015%2018%22%20fill%3D%22transparent%22%20stroke-width%3D%223%22%20stroke%3D%22rgb(255%2C%20255%2C%20255)%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-dasharray%3D%22%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
}
.beer-handle::after {
  right: 5px;
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M%200%200%20L%2024%200%20L%2024%2024%20L%200%2024%20Z%22%20fill%3D%22transparent%22%3E%3C%2Fpath%3E%3Cpath%20d%3D%22M%206%200%20L%200%206%20L%206%2012%22%20transform%3D%22translate(9%206)%20rotate(180%203%206)%22%20fill%3D%22transparent%22%20stroke-width%3D%223%22%20stroke%3D%22rgb(255%2C%20255%2C%20255)%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-dasharray%3D%22%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
}

.preview-image {
  user-select: none;
}

.preview-image::after {
  content: attr(data-beer-label);
  color: #333;
  display: block;
  word-break: keep-all;
  white-space: pre-line;
  position: absolute;
  text-align: center;
  top: 3rem;
  padding: 0.6rem 1rem;
  border-radius: 0.25rem;
  background: rgba(255, 255, 255, 0.75);
  width: 8rem;
}

.preview-image.before::after {
  left: 1rem;
}

.preview-image.after::after {
  right: 1rem;
}

.preview-image.after::before {
  content: attr(data-beer-label-green);
  display: block;
  position: absolute;
  top: 8rem;
  right: 1rem;
  line-height: 1;
  padding: 0.5rem;
  border-radius: 0.25rem;
  color: white;
  background: #21cf05;
  font-size: 12px;
  width: 5.8rem;
}
